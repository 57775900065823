@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');

html {
  --screen-sm: 400px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  color: inherit;
  font-family: "Raleway", sans-serif;
}
